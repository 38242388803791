import { Injectable } from '@angular/core';
import { Policy } from '@interfaces/policy';
import { PetType } from '@shared/enums/pet-type.enum';
import { PolicyPaymentFrequency } from '../enums/policy-payment-frequency.enum';
import { PolicyPaymentStatus } from '../enums/policy-payment-status.enum';
import { PolicyPaymentType } from '../enums/policy-payment-type.enum';
import { PolicyStatus } from '../enums/policy-status.enum';
import { PolicyExcess } from '../interfaces/policy-excess';
import { PolicyRenewalWindow } from '../interfaces/policy-renewal-window.interface';
import { RenewalPeriod } from '../enums/renewal-period.enum';

export enum PolicyStates {
  Cancelled,
  DueForRenewal,
  Overdue,
  PaidToDate,
}

export enum PetDetailsEditState {
  ChangeNotAllowed,
  HasOpenClaim,
  InvalidAddress,
  OutstandingPayment,
  BreedContactUs,
  BreedMongrel,
  Editing,
  InProgress,
  Success,
  Failure,
}

export enum ExcessEditState {
  ChangeNotAllowed,
  OngoingPayment,
  Editing,
  Loading,
  ChangeFailed,
  MaxChangesMadeInRenewal,
  OutstandingPayment,
}

export const isDog = function (policy: Policy): boolean {
  return policy.pet.type === PetType.Dog;
};

export const isHorse = function (policy: Policy): boolean {
  return policy.pet.type === PetType.Horse;
};

export const isRider = function (policy: Policy): boolean {
  return policy.pet.type === PetType.Rider;
};

export const isAviva = function (policy: Policy): boolean {
  return (
    policy.coverLevel.toLowerCase() === 'silver'
    || policy.coverLevel.toLowerCase() === 'gold'
    || policy.coverLevel.toLowerCase() === 'platinum'
  );
};

export const isLimitedAccess = function (policy: Policy): boolean {
  return (
    policy.coverLevel.toLowerCase() === 'basic'
    || isAviva(policy)
  );
};

export const isV3 = function(policy: Policy): boolean {
  return !!(policy?.productVersion) && policy.productVersion.startsWith('V3');
};

export const isVH1 = function(policy: Policy): boolean {
  return isHorse(policy) && !!(policy?.productVersion) && policy.productVersion.startsWith('VH1');
};

export const isMonthly = function(policy: Policy): boolean { 
  return policy.paymentFrequency === PolicyPaymentFrequency.Monthly;
};

export const getPolicyName = function(policy: Policy): string {
  if (policy && policy.pet) {
    if (isRider(policy)) {
      return policy.pet.riderFirstName?.trim() || '';
    } else {
      return policy.pet.petName?.trim() || '';
    }
  }

  return '';
};

@Injectable({
  providedIn: 'root',
})
export class PolicyMethods {
  static Texts = {
    Placeholder: 'n/a',
    PaidToDate: 'AUTH.PAYMENT.SUMMARY.TABLE.PAIDTODATE',
    DueForRenewal: 'AUTH.PAYMENT.SUMMARY.TABLE.DUEFORRENEWAL',
    Overdue: 'AUTH.PAYMENT.SUMMARY.TABLE.OVERDUE',
    Monthly: 'AUTH.PAYMENT.SUMMARY.TABLE.MONTHLY',
    Annual: 'AUTH.PAYMENT.SUMMARY.TABLE.ANNUAL',
    Cancelled: 'AUTH.PAYMENT.SUMMARY.TABLE.CANCELLED',
    OverdueMissedPayment: 'AUTH.PAYMENT.SUMMARY.TABLE.OVERDUEMISSEDPAYMENT',
  };

  isAviva(policy: Policy): boolean { return isAviva(policy); }

  isLimitedAccess(policy: Policy): boolean { return isLimitedAccess(policy); }

  isCat(policy: Policy): boolean {
    return policy.pet.type === PetType.Cat;
  }

  isDog(policy: Policy): boolean {
    return policy.pet.type === PetType.Dog;
  }

  isPet(policy: Policy): boolean {
    return this.isCat(policy) || this.isDog(policy);
  }

  isHorse(policy: Policy): boolean { return isHorse(policy); }

  isPetOrHorse(policy: Policy): boolean {
    return this.isPet(policy) || this.isHorse(policy);
  }

  isRider(policy: Policy): boolean { return isRider(policy); }

  isYoungRider(policy: Policy): boolean {
    return policy.coverLevel.toLocaleLowerCase() === 'young rider';
  }

  isCancelled(policy: Policy): boolean {
    return policy.status.toLocaleLowerCase() === PolicyStatus.Cancelled;
  }
  
  isExpired(policy: Policy): boolean {
    return policy.status.toLocaleLowerCase() === PolicyStatus.Expired;
  }

  isPrePup(policy: Policy): boolean {
    return policy.productVersion == null
      || policy.productVersion === ''
      || policy.productVersion.startsWith("V1"); 
  }

  isV3(policy: Policy): boolean { return isV3(policy); }
  
  isVH1(policy: Policy): boolean { return isVH1(policy); }

  getState(policy: Policy): PolicyStates {
    
    if (this.isCancelled(policy)) {
      return PolicyStates.Cancelled;
    }

    if (policy.payments?.monthlyCustomerCanMakeMissedPayment) {
      return PolicyStates.Overdue;
    }

    if (policy.payments?.annualCustomerCanMakePayment) {
      return PolicyStates.DueForRenewal;
    }

    if (!this.isPaymentOutstanding(policy)) {
      return PolicyStates.PaidToDate;
    }

    if (
      policy.paymentFrequency === PolicyPaymentFrequency.Annually &&
      this.isDueForRenewal(policy)
    ) {
      return PolicyStates.DueForRenewal;
    }

    return PolicyStates.Overdue;
  }

  getPolicyName(policy: Policy): string { return getPolicyName(policy); }

  getFrequencyText(policy: Policy): string {
    if (!policy) {
      return PolicyMethods.Texts.Placeholder;
    }

    return isMonthly(policy)
      ? PolicyMethods.Texts.Monthly
      : PolicyMethods.Texts.Annual;
  }

  getPremium(policy: Policy): string {
    if (!policy) {
      return PolicyMethods.Texts.Placeholder;
    }

    return isMonthly(policy)
      ? `${policy.monthlyTotal}`
      : `${policy.annualTotal}`;
  }

  getPaymentStatusText(policy: Policy): string {
    if (!policy) {
      return 'n/a';
    }

    if (policy.paymentStatus === PolicyPaymentStatus.Cancelled) {
      return PolicyMethods.Texts.Cancelled;
    }

    if (policy.payments?.annualCustomerCanMakePayment) {
      return PolicyMethods.Texts.DueForRenewal;
    }

    if (policy.payments?.monthlyCustomerCanMakeMissedPayment) {
      return PolicyMethods.Texts.OverdueMissedPayment;
    }

    if (!this.isPaymentOutstanding(policy)) {
      return PolicyMethods.Texts.PaidToDate;
    }

    if (
      (policy.paymentFrequency === PolicyPaymentFrequency.Annually &&
      this.isDueForRenewal(policy))
    ) {
      return PolicyMethods.Texts.DueForRenewal;
    }

    return PolicyMethods.Texts.Overdue;
  }

  isPaymentOutstanding(policy: Policy): boolean {
    return policy.outstandingPayment > 0;
  }

  isDueForRenewal(policy: Policy): boolean {
    const today = Date.now();
    const renewalDate = new Date(policy.renewalDate);
    const inceptionDate = new Date(policy.inceptionDate);

    return (
      renewalDate.getTime() > today &&
      renewalDate.getTime() === inceptionDate.getTime()
    );
  }

  getPetType(policy: Policy): string {
    return policy.pet.type;
  }

  canReviewExcess(policyExcess: PolicyExcess) {
    return policyExcess.allowedExcesses.length > 0;
  }

  getPaymentType(policy: Policy): PolicyPaymentType {
    if (policy.payments?.annualCustomerCanMakePayment) {
      return PolicyPaymentType.AnnualRenewal;
    }

    if (policy.payments?.monthlyCustomerCanMakeMissedPayment) {
      return PolicyPaymentType.MissedPayment;
    }

    return PolicyPaymentType.None;
  }

  canSelectCpaConsent(paymentType: PolicyPaymentType) {
    switch (paymentType) {
      case PolicyPaymentType.AnnualRenewal:
      case PolicyPaymentType.AnnualExcess:
      case PolicyPaymentType.Renewal:
        return true;
      case PolicyPaymentType.MissedPayment:
      case PolicyPaymentType.DOBChange:
      case PolicyPaymentType.BreedChange:
      case PolicyPaymentType.GenderChange:
      case PolicyPaymentType.None:
        return false;
      default:
        return false;
    }
  }
  

  getFormattedPetNameText(allPetNames: string[] = [], petToUpdate: string) {
    const otherPetNames = allPetNames.filter(p => p !== petToUpdate);

    if (otherPetNames.length === 1) {
      return otherPetNames[0];
    }

    const petNameText = [];

    for (let i = 0; i < otherPetNames.length; i++) {
      const petName = otherPetNames[i];
      if (i === 0) {
        petNameText.push(petName);
      } else if (i === otherPetNames.length - 1) {
        petNameText.push(' and ' + petName);
      } else {
        petNameText.push(', ' + petName);
      }
    }

    return petNameText.join('');
  }

  getPetDetailsEditState(policy: Policy, changeAllowed: boolean, hasOpenClaim = false) {
    if (!changeAllowed) {
      return PetDetailsEditState.ChangeNotAllowed;
    }

    if (hasOpenClaim) {
      return PetDetailsEditState.HasOpenClaim;
    }

    if (!policy.payments.hasValidAddress) {
      return PetDetailsEditState.InvalidAddress;
    }

    if (policy.outstandingPayment > 0) {
      return PetDetailsEditState.OutstandingPayment;
    }

    return PetDetailsEditState.Editing;
  }
  
  getExcessEditState(
    canReviewExcess: boolean,
    ongoingPayment: boolean,
    isInRenewalWindow: boolean,
    policy: Policy) {
    if(!canReviewExcess && isInRenewalWindow){
      return ExcessEditState.MaxChangesMadeInRenewal;
    }

    if (ongoingPayment) {
      return ExcessEditState.OngoingPayment;
    }

    if (!canReviewExcess) {
      return ExcessEditState.ChangeNotAllowed;
    }

    if (policy.outstandingPayment > 0) {
      return ExcessEditState.OutstandingPayment;
    }
    return ExcessEditState.Editing;
  }
  
  getPetAge(petDob: Date, today: Date): number{
    const years = today.getFullYear() - petDob.getFullYear();

    return today.getMonth() < petDob.getMonth() ||
      (today.getMonth() === petDob.getMonth() && today.getDate() < petDob.getDate())
      ? years - 1
      : years;
  }
  
  getCopaymentAge(policy: Policy): number {
    return this.isCat(policy) ? 10 : 8;
  } 
  
  isInRenewalWindowButBeforeInceptionDate(policy: Policy): boolean {
    const today = new Date();
    // Policy.inceptionDate is a string, even though it claims to be a date.
    const inceptionDate = typeof(policy.inceptionDate) === 'string'
      ? new Date(Date.parse(policy.inceptionDate))
      : policy.inceptionDate;

    return !this.isMidTerm(policy) && today.getTime() < inceptionDate.getTime();
  }

  isMidTerm(policy: Policy): boolean {
    const inceptionDate = new Date(policy.inceptionDate);
    const coolingOffPeriodEndDate = new Date(inceptionDate);
    coolingOffPeriodEndDate.setDate(coolingOffPeriodEndDate.getDate() + 14);
    return coolingOffPeriodEndDate.getTime() < new Date().getTime();
  }

  getRenewalWindow(policy: Policy): PolicyRenewalWindow {
    // Policy.inceptionDate is a string, even though it claims to be a date.
    const inceptionDate = typeof(policy.inceptionDate) === 'string'
      ? new Date(Date.parse(policy.inceptionDate))
      : policy.inceptionDate;
    const startDate = new Date(inceptionDate);
    startDate.setDate(startDate.getDate() - 29);
    const endDate = new Date(inceptionDate);
    endDate.setDate(endDate.getDate() + 14);
    return {
      startDate,
      endDate,
    };
  }

  isNewBusiness(policy: Policy) {
    return !policy.previousPolicyRef;
  }

  isStillWithinCoolingOffPeriod(policy: Policy) {
    return !this.isMidTerm(policy) || new Date(policy.inceptionDate) > new Date();
  }

  getEffectiveDateForMTA(dayOfMonth: number, today = new Date()) {
    // Get the current month and year
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Calculate the next month and year
    let nextMonth = currentMonth;
    let nextYear = currentYear;

    // if inception date is past in this month, 
    // show inception date of next month otherwise current month
    if(today.getDate() >  dayOfMonth){
      nextMonth += 1;
    }

    // Handle case where next month is in the following year
    if (nextMonth === 12) {
      nextMonth = 0;
      nextYear += 1;
    }

    // Get the last day of the next month
    const lastDayOfNextMonth = new Date(Date.UTC(nextYear, nextMonth + 1, 0)).getDate();

    // If the same day exists in the next month, return it
    if (dayOfMonth <= lastDayOfNextMonth) {
      return new Date(Date.UTC(nextYear, nextMonth, dayOfMonth));
    }

    // If the same day does not exist, return the last day of the next month
    return new Date(Date.UTC(nextYear, nextMonth, lastDayOfNextMonth));
  }

  isLive(policy: Policy) {
    const liveStatuses: string[] = 
    [
      PolicyStatus.Live, 
      PolicyStatus.LiveDocsReqd, 
      PolicyStatus.RenewalLive,
    ];
    return liveStatuses.includes(policy.status.toLowerCase());
  }

  getRenewalPeriod(policy: Policy): RenewalPeriod {
    
    if (this.isMidTerm(policy)){
      return RenewalPeriod.OutsideWindow;
    }

    const daysUntilRenewal = this.getNumberOfDaysBetweenDates(
      new Date(),
      new Date(policy.inceptionDate),
    );

    if (daysUntilRenewal < 0) {
      return RenewalPeriod.AfterInceptionDate;
    } else if (daysUntilRenewal > 0) {
      return RenewalPeriod.BeforeInceptionDate;
    } else {
      return RenewalPeriod.OnInceptionDate;
    };
  }

  getNumberOfDaysBetweenDates(firstDate: Date, secondDate: Date) {
    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);
    const firstDateInMs = firstDate.getTime();
    const secondDateInMs = secondDate.getTime();

    const differenceBtwDates = secondDateInMs - firstDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    return Math.round(differenceBtwDates / aDayInMs);
  }

  isRenewed(policy: Policy) {
    return policy?.status.toLocaleLowerCase() === PolicyStatus.Renewed;
  }

  isEligibleForRiderClosureBanner(policy: Policy) {
    const daysUntilEndDate  = this.getNumberOfDaysBetweenDates(
      new Date(), 
      new Date(policy.expiryDate),
    );
    return isRider(policy) && this.isLive(policy) && daysUntilEndDate  <= 28;
  }
}
